import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTE_ONBOARDING } from '../constants/constants';
import { useTranslation } from 'react-i18next';

export const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Stack spacing={2} mt={2} direction="row">
      <Button
        variant="contained"
        onClick={() => {
          navigate(ROUTE_ONBOARDING, { replace: true });
        }}
      >
        {t('login')}
      </Button>
    </Stack>
  );
};
