import { ENDPOINT_CREDENTIAL_OFFER } from '../constants/constants';
import { getBaseUrl, joinPath } from './utils';

const useCredentialOfferUrl = () => {
  const credentialOfferUrl = `openid-credential-offer://?credential_offer_uri=${encodeURIComponent(
    joinPath(
      getBaseUrl(),
      ENDPOINT_CREDENTIAL_OFFER,
      '1234-token-5678',
    ),
  )}`;
  return {
    credentialOfferUrl,
  };
};

export default useCredentialOfferUrl;
