import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EnvContext from '../contexts/EnvContext';

type Props = {
  children: JSX.Element;
};

interface AndroidType {
  reloadWebApp(): void;
  getApiKey(): string;
  isMobile(): boolean;
}

declare var AndroidInterface: AndroidType | undefined;

export const EnvProvider: FC<Props> = ({ children }) => {
  const hasAndroidInterface = () => {
    return (
      typeof AndroidInterface !== 'undefined' && AndroidInterface
    );
  };

  const apiKey = hasAndroidInterface()
    ? AndroidInterface!.getApiKey()
    : process.env.REACT_APP_API_KEY || '';

  const isMobile = hasAndroidInterface()
    ? AndroidInterface!.isMobile()
    : process.env.REACT_APP_IS_MOBILE === 'true' || false;

  const reloadWebApp = () => {
    if (hasAndroidInterface()) {
      AndroidInterface!.reloadWebApp();
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const backPressedFn = (event: any) => {
      console.log('backPressedEvent');
      navigate(-1);
    };

    window.addEventListener('backPressedEvent', backPressedFn, false);
    return () => {
      window.removeEventListener('backPressedEvent', backPressedFn);
    };
  });

  return (
    <EnvContext.Provider
      value={{
        apiKey,
        isMobile,
        reloadWebApp,
      }}
    >
      {children}
    </EnvContext.Provider>
  );
};

export default EnvProvider;
