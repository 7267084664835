const sk = {
  translation: {
    appName: 'Wallet provider',
    back: 'Späť',
    finish: 'Koniec',
    login: 'Prihlásiť',
    close: 'Zatvoriť',
    next: 'Ďalej',
    onboarding: 'Onboarding',
    stepScanQrCode: 'Naskenujte QR kód',
    stepPairPhone: 'Spárovanie telefónu',
    stepPairPhoneEnterCode: 'Zadajte kód zobrazený na telefóne',
    stepPairPhoneCode: 'XXX-XXX',
    stepPairPhoneFinished: 'Telefón spárovaný',
    stepPairPhoneFinishedDetail:
      'Telefón spárovaný. Pokračujte podľa inštrukcií na telefóne.',
  },
};

export default Object.assign({}, sk);
