import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import MuiToolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router-dom';
import { ROUTE_ONBOARDING } from '../constants/constants';
import AccountCircle from '@mui/icons-material/AccountCircle';

function Toolbar() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const navBarTitle = useMemo(() => {
    if (pathname.endsWith(ROUTE_ONBOARDING)) {
      return t('onboarding');
    } else {
      return t('login');
    }
  }, [pathname, t]);

  return (
    <MuiToolbar>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        {navBarTitle}
      </Typography>
      <IconButton
        onClick={() => {}}
        color="inherit"
        edge="end"
        size="large"
        sx={{ ml: 2 }}
      >
        <AccountCircle />
      </IconButton>
    </MuiToolbar>
  );
}

export default Toolbar;
