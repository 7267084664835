import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Button from '@mui/material/Button';
import StepLabel from '@mui/material/StepLabel';
import { StepScanQRCode } from '../components/StepScanQRCode';
import { OneTimePassword } from '../components/StepOneTimePassword';

const OnboardingPage = () => {
  const { t } = useTranslation();
  const steps = [
    t('stepScanQrCode'),
    t('stepPairPhone'),
    t('stepPairPhoneFinished'),
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Grid
      className="container"
      container
      direction="row"
      wrap="nowrap"
      marginTop={4}
      padding={4}
    >
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 0 && (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {t('stepScanQrCode')}
              </Typography>
              <StepScanQRCode />
            </>
          )}
          {activeStep === 1 && (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {t('stepPairPhoneEnterCode')}
              </Typography>
              <OneTimePassword />
            </>
          )}
          {activeStep === 2 && (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {t('stepPairPhoneFinishedDetail')}
              </Typography>
            </>
          )}
          {activeStep === steps.length ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {t('stepPairPhoneFinishedDetail')}
              </Typography>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}
              >
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}
              >
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t('back')}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1
                    ? t('finish')
                    : t('next')}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default OnboardingPage;
