import { createContext, useContext } from 'react';

type EnvContextType = {
  apiKey: string;
  isMobile: boolean;
  reloadWebApp: () => void;
};

export const useEnv = () => useContext(EnvContext) as EnvContextType;

export const EnvContext = createContext<EnvContextType | null>(null);

export default EnvContext;
