import { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFetchDataAction, {
  Method,
} from '../utils/useFetchDataAction';
import { ENDPOINT_AUTHORIZE_SESSION } from '../constants/constants';

export const OneTimePassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');

  const { dataLoading, fetchData } = useFetchDataAction();

  const displayPassword = useMemo(() => {
    if (password.length > 3) {
      return `${password.slice(0, 3)}-${password.slice(3)}`;
    } else {
      return password;
    }
  }, [password]);

  const authorizeOTP = useCallback(
    (pin: string) => {
      fetchData(ENDPOINT_AUTHORIZE_SESSION, Method.POST, {
        pin,
      });
    },
    [fetchData],
  );

  useEffect(() => {
    if (password.length === 6) {
      authorizeOTP(password);
    }
  }, [password, authorizeOTP]);

  const setPasswordFromTextField = (value: string) => {
    const enteredOTP = value.toUpperCase().replace(/[^a-z0-9]/gi, '');
    console.log(`${value}->${enteredOTP}`);
    if (enteredOTP.length <= 6) {
      setPassword(enteredOTP);
    }
  };
  return (
    <>
      {!dataLoading && (
        <TextField
          label={t('stepPairPhoneCode')}
          sx={{ m: 1, width: '25ch' }}
          value={displayPassword}
          onChange={(event) => {
            setPasswordFromTextField(event.target.value);
          }}
        />
      )}
      {dataLoading && <CircularProgress />}
    </>
  );
};
