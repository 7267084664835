export const ellipsis = (string: string, length: number) => {
  return string.length > length
    ? `${string.substring(0, length)}...`
    : string;
};

export const isEmpty = (value: any) => {
  return value === null || value === undefined;
};

export const isFulfilled = <T>(
  p: PromiseSettledResult<T>,
): p is PromiseFulfilledResult<T> => p.status === 'fulfilled';

export const isRejected = <T>(
  p: PromiseSettledResult<T>,
): p is PromiseRejectedResult => p.status === 'rejected';

export function joinPath(root: string, ...paths: string[]) {
  return root ? [root.replace(/\/$/, ''), ...paths].join('/') : root;
}

export const getBaseUrl = () => {
  // eslint-disable-next-line no-restricted-globals
  const { protocol, hostname, port } = location;

  return `${
    process.env.REACT_APP_API_BASE_URL ||
    (process.env.NODE_ENV === 'development'
      ? `${protocol}//${hostname}:3000`
      : `${protocol}//${hostname}:${port}`)
  }/api/v1/`;
};
