import { createTheme } from '@mui/material/styles';

export const SCROLL_PANEL_WIDTH = 312.5;
export const HEIGHT_OF_INPUT = 41.3;

export const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  RED: '#CC3033',
  GREY: '#CCCCCC',
  MOUBLUE_200: '#405992',
  MOUBLUE_500: '#113077',
  MOUBLUE_700: '#0b2153',
  TEAL_200: '#27C1BB',
  TEAL_700: '#1b8782',
  YELLOW: '#FFC300',
};

// export const FONT_WEIGHT = {
//   THIN: 100,
//   EXTRA_LIGHT: 200,
//   LIGHT: 300,
//   REGULAR: 400,
//   MEDIUM: 500,
//   SEMI_BOLD: 600,
//   BOLD: 700,
//   EXTRA_BOLD: 800,
//   BLACK: 900,
//   EXTRA_BLACK: 950,
// };

// export const BORDER_RADIUS = {
//   NORMAL: 10.5,
//   MEDIUM: 9.629,
//   SMALL: 6.072,
//   NONE: "unset",
// };

// export const FONT_FAMILY = {
//   PROXIMA_NOVA: "proxima-nova",
// };

export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.MOUBLUE_500,
      contrastText: COLORS.WHITE,
      dark: COLORS.MOUBLUE_700,
      light: COLORS.WHITE,
    },
    secondary: {
      main: COLORS.TEAL_200,
      contrastText: COLORS.BLACK,
      dark: COLORS.TEAL_700,
      light: COLORS.WHITE,
    },
    text: {
      secondary: COLORS.MOUBLUE_700,
    },
  },
  // typography: {
  //   h1: {
  //     fontSize: 30.74,
  //     fontFamily: FONT_FAMILY.PROXIMA_NOVA,
  //     fontWeight: FONT_WEIGHT.EXTRA_BOLD,
  //     letterSpacing: 4,
  //     textTransform: "uppercase",
  //   },
  //   // Main menu
  //   h2: {
  //     fontSize: 21,
  //     fontFamily: FONT_FAMILY.PROXIMA_NOVA,
  //     fontWeight: FONT_WEIGHT.BOLD,
  //     textTransform: "uppercase",
  //   },
  //   // Title in list
  //   h3: {
  //     fontSize: 21,
  //     fontFamily: FONT_FAMILY.PROXIMA_NOVA,
  //     fontWeight: FONT_WEIGHT.MEDIUM,
  //   },
  //   // Description in list
  //   subtitle1: {
  //     fontSize: 12,
  //     fontFamily: FONT_FAMILY.PROXIMA_NOVA,
  //     fontWeight: FONT_WEIGHT.MEDIUM,
  //   },

  //   // Send to in messages detail
  //   subtitle2: {
  //     fontSize: 18,
  //     fontFamily: FONT_FAMILY.PROXIMA_NOVA,
  //     fontWeight: FONT_WEIGHT.EXTRA_BOLD,
  //     textTransform: "uppercase",
  //   },
  //   // Input header
  //   h4: {
  //     fontSize: 16,
  //     fontFamily: FONT_FAMILY.PROXIMA_NOVA,
  //     fontWeight: FONT_WEIGHT.EXTRA_BOLD,
  //     textTransform: "uppercase",
  //   },
  //   // Logged in as
  //   h6: {
  //     fontSize: 13,
  //     fontWeight: FONT_WEIGHT.EXTRA_BOLD,
  //     letterSpacing: 2,
  //     fontFamily: FONT_FAMILY.PROXIMA_NOVA,
  //     textTransform: "uppercase",
  //   },
  //   // logged in username in main menu
  //   h5: {
  //     fontSize: 20,
  //     fontWeight: FONT_WEIGHT.SEMI_BOLD,
  //     fontFamily: FONT_FAMILY.PROXIMA_NOVA,
  //   },

  //   button: {
  //     fontSize: 15,
  //     fontWeight: FONT_WEIGHT.BOLD,
  //     fontFamily: FONT_FAMILY.PROXIMA_NOVA,
  //     textTransform: "uppercase",
  //   },
  //   body1: {
  //     fontSize: 18,
  //     fontWeight: FONT_WEIGHT.SEMI_BOLD,
  //     fontFamily: FONT_FAMILY.PROXIMA_NOVA,
  //   },
  //   overline: {
  //     fontSize: 14,
  //     fontWeight: FONT_WEIGHT.BOLD,
  //     fontFamily: FONT_FAMILY.PROXIMA_NOVA,
  //   },
  // },
  // overrides: {
  //   MuiListItemText: {
  //     root: {
  //       textTransform: 'uppercase',
  //     },
  //   },
  // },
});
