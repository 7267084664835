import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from './components/Toolbar';
import { styled } from '@mui/material/styles';
import { Routes, Route } from 'react-router-dom';
import {
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_ONBOARDING,
} from './constants/constants';
// import { Home } from './components/Home';
import Layout from './Layout';
import OnboardingPage from './pages/OnboardingPage';
import LoginPage from './pages/LoginPage';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

function App() {
  return (
    <div>
      <CssBaseline />
      <AppBar position="fixed" color="primary">
        <Toolbar />
      </AppBar>
      <Offset />
      <Routes>
        <Route path={ROUTE_HOME} element={<Layout />}>
          <Route index element={<LoginPage />} />
          <Route path={ROUTE_LOGIN} element={<LoginPage />} />
          <Route
            path={ROUTE_ONBOARDING}
            element={<OnboardingPage />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
