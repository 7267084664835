import Grid from '@mui/material/Grid';
import { Login } from '../components/Login';
const LoginPage = () => {
  return (
    <Grid
      className="container"
      container
      direction="column"
      wrap="nowrap"
    >
      <Grid item xs={12}>
        <Login />
      </Grid>
    </Grid>
  );
};

export default LoginPage;
