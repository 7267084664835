import { useState, useEffect, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { useEnv } from '../contexts/EnvContext';

// eslint-disable-next-line no-restricted-globals
const { protocol, hostname, port } = location;
const BASE_URL = `${
  process.env.REACT_APP_API_BASE_URL ||
  (process.env.NODE_ENV === 'development'
    ? `${protocol}//${hostname}:3000`
    : `${protocol}//${hostname}:${port}`)
}/api/v1/`;

export enum Method {
  GET,
  POST,
}

const useFetchDataAction = () => {
  const [dataLoading, setDataLoading] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource | null>(null);
  const { apiKey } = useEnv();

  const fetchData = useCallback(
    async (
      url: string,
      method: Method,
      data?: any,
      onSuccess?: Function,
      onError?: Function,
    ) => {
      const headers = {
        'X-API-Key': apiKey,
      };

      setDataLoading(true);
      const source = axios.CancelToken.source();
      setCancelTokenSource(source);
      try {
        const urlWithBase = url.startsWith('http')
          ? url
          : `${BASE_URL}${url}`;
        let response;

        if (method === Method.GET) {
          response = await axios.get(urlWithBase, {
            cancelToken: source.token,
            headers: headers,
          });
        }
        if (method === Method.POST) {
          response = await axios.post(urlWithBase, data, {
            cancelToken: source.token,
            headers: headers,
          });
        }

        if (!response) throw new Error('Invalid method!');

        setDataLoading(false);
        setCancelTokenSource(null);
        if (response.data?.success === false && onError) {
          onError(new Error(response.data?.message));
        } else if (onSuccess) {
          onSuccess(response.data);
        }
      } catch (error: any) {
        console.error(error);
        setDataLoading(false);
        if (onError) {
          const serviceError = error.response?.data?.message;
          if (serviceError) {
            onError(new Error(serviceError));
          } else {
            onError(error);
          }
        }
      }
      // Beware of onSuccess and onError - must be as useCallback
    },
    [apiKey],
  );

  useEffect(() => {
    console.log('useFetchDataAction');

    return () => {
      console.log(`useFetchDataAction -> unmount`);
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }
    };
    // We want also otherDependencies as dependency :)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dataLoading,
    fetchData,
  };
};

export default useFetchDataAction;
