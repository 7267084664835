import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import i18n from 'i18next';
import sk from './assets/sk';
import { initReactI18next } from 'react-i18next';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { theme } from './appTheme';
import { SnackbarProvider } from 'notistack';
import EnvProvider from './providers/EnvProvider';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      sk,
    },
    lng: 'sk',
    fallbackLng: 'sk',

    interpolation: {
      escapeValue: false,
    },
  });
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <EnvProvider>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
            <App />
        </SnackbarProvider>
      </EnvProvider>
    </BrowserRouter>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
