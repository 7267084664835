import { Outlet } from 'react-router-dom';

function Layout() {
  console.log('Layout -> render');
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default Layout;
